<template>
  <div class="footer">
    
    <div
      class="footer__container"
      :class="{'footer__container_justify-center': !showButtons}"
      @click.stop="onLangBlur()"
    >
      <div class="footer__buttons" v-if="showButtons">
        <button
          v-if="showFeedbackButton"
          class="footer-link"
          @click.prevent="onFeedbackClick"
        >
          <base-icon class="footer-link__icon" width="16" height="16" viewBox="0 0 16 16">
            <icon-feedback />
          </base-icon>
          <span class="footer-link__text">Website Feedback</span>
        </button>
        <div class="buttons__localization" v-if="showLangSwitch">
          <Button 
            @click.stop="onLangClick()"
            @blur="onLangBlur()"
            @hover="showLangDropdown = true"
            variant="ghost"
            view="light"
            size="md"
            class="localization__lang"
            :class="!$root.isMobile ? 'localization__lang--large' : ''"
          >
            <slot name="before">
              <span class="rr-button__icon">
                <base-icon width="16" height="16">
                  <icon-global fill="currentColor" />
                </base-icon>
              </span>
            </slot>
            <slot>
              <span class="rr-button__text">{{ langTitle }}</span>
              <div v-if="$store.state.showLang">
                <a href="#" @click.prevent class="lang__menu-item">
                  <div class="menu-item__submenu" style="bottom: 52px;" v-if="$root.isMobile || $root.isTablet" :style="{'display': showLangDropdown ? 'block' : 'none'}">
                    <div
                      v-for="item in menuLang"
                      :key="item.id"
                      class="submenu__item"
                      @click.prevent="onLangSelect(item.id)"
                    >
                      <div class="submenu__image">
                        <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
                      </div>
                      <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                        {{ item.text }}
                      </div>
                    </div>
                  </div>
                  <div style="position: absolute; bottom: 40px; width: max-content; min-width: 100%; z-index: 3; padding-bottom: 12px;">
                    <div class="menu-item__submenu" v-if="!$root.isMobile && !$root.isTablet">
                      <div
                        v-for="item in menuLang"
                        :key="item.id"
                        class="submenu__item"
                        @click.prevent="onLangSelect(item.id)"
                      >
                        <div class="submenu__image">
                          <img :src="'/img/lang/' + item.id + '.svg'" :alt="item.id" />
                        </div>
                        <div class="submenu__text" @click.prevent="onLangSelect(item.id)">
                          {{ item.text }}
                        </div>
                      </div>                  
                    </div>
                  </div>
                </a>
              </div>
            </slot>
          </Button>
        </div>
      </div>
      <div class="footer__copyright">
        © {{ new Date().getFullYear() }}, WITISI Sport GmbH. All rights reserved.
        &nbsp;| &nbsp;<a href="/Privacy_Policy.pdf" target="_blank" class="copyright__link">Privacy</a>
        &nbsp;| &nbsp;<a :href="lang === 'de' ? '/AGB.pdf' : '/Our_Terms_and_Conditions.pdf'" target="_blank" class="copyright__link">Terms</a>
        &nbsp;| &nbsp;<a href="/impressum" class="copyright__link" v-html="$t('impressum/title')"></a>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters, mapActions } from 'vuex';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconGlobal from '@/components/icons/icon-global.vue';
import IconFeedback from '@/components/icons/icon-feedback.vue';
import { MenuLang } from '@/menu-lang';

export default {
  name: 'Footer',
  components: {
    BaseIcon,
    IconGlobal,
    IconFeedback,
  },
  data() {
    return {
      menuLang: MenuLang,
      langTitle_: '',
      showLangDropdown: false,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['lang', 'languages']),
    langTitle() {
      return this.langTitle_;
    },
    showButtons() {
      return this.showLangSwitch || this.showFeedbackButton;
    },
    showFeedbackButton() {
      return true;
    },
    showLangSwitch() {
      return this.menuLang.length > 1 && this.$store.state.showLang;
    },
  },
  watch: {
    lang() {
      this.onLangChanged();
    }
  },
  methods: {
    ...mapActions('SettingsState', ['setLang']),
    async onLangChanged() {
      const found = this.menuLang.filter((i) => i.id === this.lang);
      if (found.length == 0) this.langTitle_ = '';
      else this.langTitle_ = found[0].text;
    },
    async onLangClick() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
    },
    async onLangBlur() {
      const vm = this;
      setTimeout(() => { vm.showLangDropdown = false; }, 250);
    },
    async onLangSelect(lang) {
      if (!this.$root.isMobile && !this.$root.isTablet) {
        this.showLangDropdown = false;    
        setTimeout(() => { this.showLangDropdown = true; }, 100);
      }
      
      await this.setLang(lang);
    },
    setLanguagesAvailable() {
      const langs = this.languages;
      if (langs) {
        const result = [];
        for (let i = 0; i < MenuLang.length; i += 1) {
          if (langs.includes(MenuLang[i].id)) {
            result.push(MenuLang[i]);
          }
        }
        this.menuLang = result;
        return;
      }
      this.menuLang = MenuLang;
    },
    onFeedbackClick() {
      const w = window;
      if (w.Userback) {
        w.Userback.open();
      }
    },
  },
  mounted() {
    if (!this.$root.isMobile && !this.$root.isTablet) {
      this.showLangDropdown = true;
    }

    this.setLanguagesAvailable();

    this.onLangChanged();
  },
};
</script>


<style lang="scss" scoped>
  @import "./footer";
</style>
