import axios, { AxiosInstance } from "axios";


export class OrdersApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/order-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getOrder(payload: { orderId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
  
    const result: { status: number, data: any } = await this.api
      .get<any>(`/orders/${ payload.orderId }`, {
        headers,
      })
      .then(response => {
          return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getOrders(payload: { query: {}, token: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
  
    const result: { status: number, data: any } = await this.api
      .get<any>('/orders', {
        headers,
        params: {
          ...payload.query,
          sortBy: 'createdOn',
          descending: true,
        },
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getItems(payload: { orderId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
  
    const result: { status: number, data: any } = await this.api
      .get<any>(`/orders/${ payload.orderId }/items`, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
