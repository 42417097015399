<template>
  <section class="cookie-gdpr" v-if="isActive">
    <div class="cookie-gdpr__window">
      <div class="cookie-gdpr__text">
        We use cookies to improve the usability of the service and analyze traffic, as well as to personalize content, marketing, and security. By&nbsp;clicking the "Got It" button, you agree to <a href="/Cookies_Info.pdf" target="_blank">the terms of use of cookies</a>.
      </div>
      <div class="cookie-gdpr__actions">
        <div class="cookie-gdpr__actions-row">
          <div class="cookie-gdpr__action">
            <slot name="actions">
              <Button
                variant="primary"
                text="Got It"
                mobile
                wide
                @click="hide"
              ></Button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="js">
import Cookies from 'js-cookie';

export default {
  name: 'CookieGDPR',
  data() {
    return {
      isActive: Cookies.get('cookie_gdpr') !== 'true',
    };
  },
  methods: {
    show() {
      this.isActive = true;
    },
    hide() {
      this.isActive = false;
      Cookies.set('cookie_gdpr', 'true', { expires: 365 });
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./cookie-gdpr";
</style>
