export const MenuLang = [
  {
    id: "en",
    text: "English",
  },
  {
    id: "es",
    text: "Español",
  },
  {
    id: "de",
    text: "Deutsch",
  },
  {
    id: "el",
    text: "Ελληνική",
  },
  {
    id: "sk",
    text: "Slovenščina",
  },
  {
    id: "it",
    text: "Italiano",
  },
  {
    id: "cs",
    text: "Čeština",
  },
];
