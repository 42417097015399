import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios, { AxiosInstance } from "axios";
const loadImage = require("blueimp-load-image/js/load-image.all.min");

const api: AxiosInstance = axios.create({
  responseType: "json",
  headers: {
    "Content-Type": "application/json"
  }
});


@Module({
  name: "CaptureState",
  namespaced: true
})
export class CaptureState extends VuexModule {

  private image_: any | null = null;
  private file_: File | null = null;

  get image(): any | null {
    return this.image_;
  }

  get file(): File | null {
    return this.file_;
  }

  @Action
  async loadImage(file: File | String | null): Promise<void> {
    const dataImage = await loadImage(file, { 
      meta: true, noRevoke: true, orientation: true
      })
      .then(function (data: any) {
        return data;
      })
      .catch(function (err: any) {
        return null;
      });
    
    if (dataImage) {
      this.context.commit('saveImage', dataImage);
    } else {
      this.context.commit('saveImage', null);
    }      
  }

  @Action
  async loadFile(url: string): Promise<void> {    
    const result: { status: number, data: any } = await api
      .get<any>(url, { 
        responseType: 'blob'
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        // console.log('getList: ' + error.response.status);
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
      
    if (result.data !== null) {
      this.context.commit('saveFile', result.data);
    } else {
      this.context.commit('saveFile', null);
    }
  }

  @Mutation
  saveImage(data: any): void {
    this.image_ = data || null;
  }

  @Mutation
  saveFile(data: any): void {
    if (!data) {
      this.file_ = null;
      return;
    }

    const file: File | null = new File([data], "face.jpg", {
      type: "image/jpeg",
    });
    this.file_ = file;
  }  

}
