import axios, { AxiosInstance } from "axios";


export class ThemesApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/themes-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getThemeImages(themeId: string, lang?: string): Promise<{ status: number, data: any }> {
    
    const theme: string = themeId ? themeId : "MySport";
    const result: { status: number, data: any } = await this.api
      .get<any>(`/themeImages`, { params: {
          themeId: theme,
          offset: 0,
          count: 50,
        },
        headers: {
          "Accept-Language": lang || "en",
        },
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
