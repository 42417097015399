import Vue from "vue";
import * as Vuex from "vuex";

import ErrorsState from "./modules/errors/errors.module";
import DrawerState from "./modules/drawer/drawer.module";
import { SettingsState } from "./modules/settings";
import { CaptureState } from "./modules/capture";
import { EventsState } from "./modules/events";
import { SearchState } from "./modules/search";
import { CatalogState } from "./modules/catalog";
import FramesState from "./modules/frames/frames.module";
import { CartState } from "./modules/cart";
import { OrdersState } from "./modules/orders";
import { InvoicesState } from "./modules/invoices";
import { ArchiveState } from "./modules/archive";


Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    buttonHome: true,
    buttonBack: false,
    buttonCart: false,
    buttonProfile: true,
    showMenu: false,
    showLang: true,
    history: [],
    goBack: "",
    goPage: "",
    selectedFile: null,
  },
  modules: {
    ErrorsState,
    DrawerState,
    SettingsState,
    CaptureState,
    EventsState,
    SearchState,
    CatalogState,
    FramesState,
    CartState,
    OrdersState,
    InvoicesState,
    ArchiveState,
  }
});