







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconFeedback",
})
export default class IconFeedback extends Vue {
}
