
























































































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";

import BaseComponent from "@/components/base-component.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconMenu from "@/components/icons/icon-menu.vue";
import IconMenu2 from "@/components/icons/icon-menu2.vue";
import IconBack from "@/components/icons/icon-back.vue";
import IconShoppingCart2 from "@/components/icons/icon-shopping-cart2.vue";
import IconAppStore from "@/components/icons/icon-appstore.vue";
import IconCheckCircleS from "@/components/icons/icon-check-circle-s.vue";
import IconDown from "@/components/icons/icon-down.vue";
import IconDown2 from "@/components/icons/icon-down2.vue";
import IconGlobal from "@/components/icons/icon-global.vue";
import IconUser from "@/components/icons/icon-user.vue";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";
import { MenuMain } from "@/menu-main";
import { MenuServices } from "@/menu-services";
import { MenuLang } from "@/menu-lang";
import { UserbackColors } from "@/userback-colors";
import { ProductTypes } from "@/product-types";

@Component({
  name: "AppBarDefault",
  components: {
    BaseIcon,
    IconMenu,
    IconMenu2,
    IconBack,
    IconShoppingCart2,
    IconAppStore,
    IconCheckCircleS,
    IconDown,
    IconDown2,
    IconGlobal,
    IconUser,
  },
})
export default class AppBarDefault extends BaseComponent {

  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  menuMain: any[] = MenuMain;
  menuSelected: number = 1;

  menuServices: any[] = MenuServices;

  menuLang: any[] = MenuLang;

  productTypes: any = ProductTypes;

  showServices: boolean = false;
  showAdditional: boolean = false;
  showLangDropdown: boolean = false;

  get theme(): any {
    return this.settingsState.theme;
  }

  get itemsCount(): number {
    const allPhotos: any[] = this.getCartItems(this.productTypes.photos_with_me);
    const singlePhotos: any[] = this.getCartItems(this.productTypes.photo);
    const frames: any[] = this.getCartItems(this.productTypes.frame);
    const photosWithFrame: any[] = this.getCartItems(this.productTypes.photosWithFrame);
    let promocodes: any[] = this.getCartItems(this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    return allPhotos.length + singlePhotos.length + frames.length + photosWithFrame.length + promocodes.length;
  }

  get logoLeft(): boolean {
    if (this.$route.name === 'home' && !this.showBackButton && !this.showCustomBackButton) {
      return true;
    } else if (this.$route.name === 'cart') {
      return true;
    } else if (this.$route.name === 'order-paid') {
      return true;
    } else if (this.$route.name === 'preorder-paid') {
      return true;
    } else if (this.$route.name === 'contacts') {
      return true;
    } else if (this.$route.name === 'organizers') {
      return true;
    } else if (this.$route.name === 'photographers') {
      return true;
    } else if (this.$route.name === 'business') {
      return true;
    } else if (this.$route.name === 'my-account') {
      return true;
    } else if (this.$route.name === 'my-orders') {
      return true;
    } else if (this.$route.name === 'my-order') {
      return true;
    } else if (this.$route.name === 'impressum') {
      return true;
    } else {
      return false;
    }
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get isMySportUSA(): any {
    return this.settingsState.isMySportUSA === true;
  }

  get showBackButton(): boolean {
    return this.$store.state.buttonBack;
  }

  get showCustomBackButton(): boolean {
    return this.isMySportUSA && this.$route.name === 'home';
  }

  get keycloak() {
    return this.settingsState.keycloak;
  }

  get route() {
    return this.$route;
  }

  get routePath() {
    return this.route ? this.route.path : null;
  }

  initUserback(): boolean {
    const id = 'userback-sdk';
    const created = document.getElementById(id);
    if (created !== null) {
      return false;
    }
    const colors = this.getUserbackColors();
    const s = document.createElement('script');
    s.onload = () => {
      const w: any = window;
      const Userback = w.Userback || {};
      Userback.access_token = '9201|18058|8JKkhdf7f0dN5GxQG49YWEUMswJQ3S8bwtEzKNSNcXvaEH4r15';
      Userback.widget_settings = {
        autohide: true,
        language: this.lang,
        main_button_background_colour: colors.backgroundColour,
        main_button_text_colour: colors.textColour,
      };
    };
    s.id = id;
    s.src = 'https://static.userback.io/widget/v1.js';
    const parentNode = document.head || document.body;
    parentNode.appendChild(s);
    return true;
  }

  destroyUserback(): void {
    const id = 'userback-sdk';
    const created = document.getElementById(id);
    if (created) {
      const w: any = window;
      const Userback = w.Userback;
      if (Userback) Userback.destroy();
    }
  }

  reCreateUserback(): void {
    setTimeout(() => {
      if (!this.initUserback()) {
        setTimeout(() => this.reCreateUserback, 250);
      }
    }, 1000);
  }

  getUserbackColors(): any {
    const theme = "MySport";
    const found: any[] = UserbackColors.filter((i: any) => i.id === theme);
    const colors: any = found.length > 0 ? found[0] : { backgroundColour: "#3E3F3A", textColour: "#FFFFFF" };
    return colors;
  }

  getCartItems(type: string): any[] {
    const products: any[] = Object.assign([], this.cartState.cartItems);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.product ? r.product.productType === type : false);
      return found;
    }
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    this.destroyUserback();
  }

  @Watch("routePath")
  async onPathChanged(): Promise<void> {
    if (!this.menuMain.find((i: any) => i.href === this.routePath)) {
      this.menuSelected = 1;
    }
  }

  async onMenu(): Promise<void> {
    this.$store.dispatch('DrawerState/setShow', true);
  }

  async goBack(): Promise<void> {
    this.$store.state.goBack = this.$store.state.history.pop();
  }

  async goCustomBack(): Promise<void> {
    if (this.settingsState.backFromHome) {
      window.location.href = this.settingsState.backFromHome;
    }
  }

  async onHome(): Promise<void> {
    if (this.$route.name != 'home') {
      this.$router.push({ name: 'home' });
      this.menuSelected = 1;
    }
  }

  async onCart(): Promise<void> {
    if (this.$route.name == 'cart') return;
    this.$router.push({ name: 'cart' });
  }

  async onServicesClick(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showServices = !vm.showServices; return false; }, 250);
  }

  async onServicesBlur(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showServices = false; }, 250);
  }

  async onLangClick(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showLangDropdown = !vm.showLangDropdown; return false; }, 250);
  }

  async onLangBlur(): Promise<void> {
    const vm = this;
    setTimeout(() => { vm.showLangDropdown = false; }, 250);
  }

  async onLangSelect(lang: string): Promise<void> {
    if (!this.smOnly && !this.mdOnly) {
      this.showLangDropdown = false;    
      setTimeout(() => { this.showLangDropdown = true; }, 100);
    }
    
    await this.settingsState.setLang(lang);
  }

  onMenuItem(item: any) {
    window.scrollTo(0, 0);
    this.$router.replace({ path: item.href });
    this.menuSelected = item.id;
  }

  onProfile(): void {
    this.$router.replace({ path: '/my-account' });
  }

  onAdditionalClick(): void {
    const w: any = window;
    if (w.Userback) {
      w.Userback.open();
    }

    this.hideAdditionalMenu();
  }

  hideAdditionalMenu(): void {
    const elem = document.getElementById('app-bar__additional__menu');
    if (elem === null) return;

    elem.classList.add('additional__menu--hidden');
    setTimeout(() => { elem.classList.remove('additional__menu--hidden'); }, 250);
  }

  isItemVisible(item: any): boolean {
    if (this.isMySportUSA && item.href !== '/' && item.href !== '/contacts') {
      return false;
    }
    if (item.href === 'divider' || item.href === '/my-account') return false;
    return true;
  }

  getMenuSelected(): number {
    for (let i = 0; i < this.menuMain.length; i++) {
      if (location.pathname === this.menuMain[i].href) {
        return this.menuMain[i].id;
      }
    }
    return 1;
  }

  setLanguagesAvailable(): void {
    const langs = this.settingsState.languages;
    if (langs) {
      const result: any[] = [];
      for (let i = 0; i < MenuLang.length; i += 1) {
        if (langs.includes(MenuLang[i].id)) {
          result.push(MenuLang[i]);
        }
      }
      this.menuLang = result;
      return;
    }
    this.menuLang = MenuLang;
  }

  mounted(): void {
    if (!this.smOnly && !this.mdOnly) {
      this.showLangDropdown = true;
    }

    this.setLanguagesAvailable();

    this.menuSelected = this.getMenuSelected();
  }

}
