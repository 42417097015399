import axios, { AxiosInstance } from "axios";


export class CompetitorApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/competitor-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getCompetitors(payload: { eventId: string, search: string }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/competitors`, { params: {
          competitorsListId: payload.eventId,
          search: payload.search,
        } 
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getToken(payload: { eventId: string, payload: any, lang?: string, token?: string }): Promise<{ status: number, data: any }> {

    const headers: any = { 'Accept-Language': payload.lang || 'en' };
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .post<any>(`/competitors/${payload.eventId}/token`, {
        ...payload.payload,
      },
      {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });

    return result;
  }

  public async verifyCode(payload: { number: string, code: string }): Promise<{ status: number, data: any }> {
    const result: { status: number, data: any } = await this.api
      .get<any>(`/competitor/code`, { params: {
        ...payload,
      }})
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });

    return result;
  }

  public async verifyToken(token: string): Promise<{ status: number, data: any }> {
    const result: { status: number, data: any } = await this.api
      .get<any>(`/competitor/token`, { params: {
        token,
      }})
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });

    return result;
  }
}
