import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

import { CompetitorApi } from "@/services/api";
import { PhotoServiceApi } from "@/services/api";
import { RecognitionApi } from "@/services/api";

const competitorApi: CompetitorApi = new CompetitorApi();
const photoServiceApi: PhotoServiceApi = new PhotoServiceApi();
const recognitionApi: RecognitionApi = new RecognitionApi();

@Module({
  name: "SearchState",
  namespaced: true
})
export class SearchState extends VuexModule {

  private foundRunners: any[] = [];
  private token: any = null;
  private tokenExpired: boolean = false;
  private verificationCode_: any = null;
  private verificationToken_: any = null;
  private foundPhotos: any[] = [];
  private additionalPhotos_: any[] = [];
  private foundPhotosAll: any[] = [];
  private foundPhotosPreview: any[] = [];
  private eventImages_: any[] = [];
  private photosTotal_: number = 0;
  private additionalPhotosTotal_: number = 0;
  private selfieId_: string = "";
  private foundPersons_: any[] = [];
  private searchError_: boolean = false;
  private verificationError_: boolean = false;
  private responseCode_: number = 0;

  get runners(): any[] {
    return this.foundRunners;
  }

  get searchToken(): any {
    return this.token;
  }

  get isTokenExpired(): boolean {
    return this.tokenExpired;
  }

  get isVerificationCodeValid(): boolean {
    return this.verificationCode_?.isVerificationSuccessful === true;
  }

  get isVerificationTokenValid(): boolean {
    return this.verificationToken_?.isVerificationSuccessful === true;
  }

  get codeFromVerificationToken(): any {
    return this.verificationToken_?.code;
  }

  get photos(): any[] {
    return this.foundPhotos;
  }

  get additionalPhotos(): any[] {
    return this.additionalPhotos_;
  }
  
  get photosAll(): any[] {
    return this.foundPhotosAll;
  }

  get photosPreview(): any[] {
    return this.foundPhotosPreview;
  }

  get eventImages(): any[] {
    return this.eventImages_;
  }

  get photosTotal(): number {
    return this.photosTotal_;
  }

  get additionalPhotosTotal(): number {
    return this.additionalPhotosTotal_;
  }

  get selfieId(): string {
    return this.selfieId_;
  }

  get persons(): any[] {
    return this.foundPersons_;
  }

  get searchError(): boolean {
    return this.searchError_;
  }

  get verificationError(): boolean {
    return this.verificationError_;
  }

  get responseCode(): number {
    return this.responseCode_;
  }

  @Action
  async searchQuick(payload: any): Promise<any> {
    const result: any = await competitorApi.getCompetitors(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveRunners', result);
    } else {
      this.context.commit('saveSearchError', true);
    }
    return result;
  }

  @Action({
    commit: "clearRunners"
  })
  async clearFound(): Promise<void> {
  }  

  @Action
  async getToken(payload: any): Promise<any> {
    const result: any = await competitorApi.getToken(payload);
    if (result.status === 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveToken', result);
      this.context.commit('saveTokenExpired', false);
    } else if (result.status === 401 || result.status === 500) {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveToken', null);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveToken', null);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async verifyCode(payload: any): Promise<any> {
    const result: any = await competitorApi.verifyCode(payload);
    if (result.status === 200) {
      this.context.commit('saveVerificationError', false);
      this.context.commit('saveVerificationCode', result);
    } else if (result.status === 404 || result.status === 500) {
      this.context.commit('saveVerificationError', true);
      this.context.commit('saveVerificationCode', null);
    } else {
      this.context.commit('saveVerificationError', true);
      this.context.commit('saveVerificationCode', null);
    }
    return result;
  }

  @Action
  async verifyToken(payload: any): Promise<any> {
    const result: any = await competitorApi.verifyToken(payload);
    if (result.status === 200) {
      this.context.commit('saveVerificationError', false);
      this.context.commit('saveVerificationToken', result);
    } else if (result.status === 404 || result.status === 500) {
      this.context.commit('saveVerificationError', true);
      this.context.commit('saveVerificationToken', null);
    } else {
      this.context.commit('saveVerificationError', true);
      this.context.commit('saveVerificationToken', null);
    }
    return result;
  }

  @Action
  async searchByTag(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTag(payload);
    if (result.status === 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      if (result.data.isAdditional) {
        this.context.commit('saveAdditionalPhotos', result);
      } else {
        this.context.commit('savePhotos', result);
      }
    } else if (result.status === 401 || result.status === 500) {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async searchByTagAll(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTagAll(payload);
    if (result.status === 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      this.context.commit('savePhotosAll', result);
    } else if (result.status === 401 || result.status === 500) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async searchByTagForPreview(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTag(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      this.context.commit('savePhotosPreview', result);
    } else if (result.status === 401 || result.status === 500) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async getEventPhotos(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTag(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      this.context.commit('savePhotos', result);
    } else if (result.status === 401) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async getEventPhotosForPreview(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTag(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      this.context.commit('savePhotosPreview', result);
    } else if (result.status === 401) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async recognizePhoto(payload: any): Promise<any> {
    const result: any = await recognitionApi.recognize(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveFaceSearchData', result);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveResponseCode', result.status);
      this.context.commit('saveFaceSearchData', {
        status: result.status,
        data: {},
      });
    }
    return result;
  }

  @Action
  async loadBySelfieId(payload: any): Promise<any> {
    const result: any = await recognitionApi.loadBySelfieId(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveFaceSearchData', result);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveFaceSearchData', {
        status: result.status,
        data: {},
      });
    }
    return result;
  }

  @Action
  async loadEventImages(payload: any): Promise<any> {
    const result: any = await photoServiceApi.searchByTag(payload);
    if (result.status == 200) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', false);
      this.context.commit('saveEventImages', result);
    } else if (result.status === 401) {
      this.context.commit('saveSearchError', false);
      this.context.commit('saveTokenExpired', true);
    } else {
      this.context.commit('saveSearchError', true);
      this.context.commit('saveTokenExpired', false);
    }
    return result;
  }

  @Action
  async setItIsMe(payload: any): Promise<any> {
    const result: any = await photoServiceApi.setItIsMe(payload);
    if (result.status == 204) {
      this.context.commit('saveSearchError', false);
    } else {
      this.context.commit('saveSearchError', true);
    }
    return result;
  }
  
  @Mutation
  saveSearchError(payload: boolean): void {
    this.searchError_ = payload;
    if (payload) {
      this.foundPhotos = [];
    }
  }

  @Mutation
  saveResponseCode(payload: number): void {
    this.responseCode_ = payload;
  }

  @Mutation
  saveRunners(payload: { status: number, data: any }): void {
    if (!payload.data) {
      this.foundRunners = [];
      return;
    }
    if (Array.isArray(payload.data)) {
      this.foundRunners = payload.data;
    } else {
      this.foundRunners = payload.data.values;
    }
  }

  @Mutation
  clearRunners(): void {
    this.foundRunners = [];
  }

  @Mutation
  saveToken(payload: { status: number, data: any }): void {
    if (!payload || !payload.data) {
      this.token = null;
      return;
    }
    this.token = payload.data;
  }

  @Mutation
  saveTokenExpired(payload: boolean): void {
    this.tokenExpired = payload;
  }

  @Mutation
  saveVerificationCode(payload: { status: number, data: any }): void {
    if (!payload || !payload.data) {
      this.verificationCode_ = null;
      return;
    }
    this.verificationCode_ = payload.data;
  }

  @Mutation
  saveVerificationToken(payload: { status: number, data: any }): void {
    if (!payload || !payload.data) {
      this.verificationToken_ = null;
      return;
    }
    this.verificationToken_ = payload.data;
  }

  @Mutation
  saveVerificationError(payload: boolean): void {
    this.verificationError_ = payload;
  }

  @Mutation
  saveFaceSearchData(payload: { status: number, data: any }): void {
    if (!payload.data) return;

    this.selfieId_ = payload.data.selfieId || "";
    this.foundPersons_ = payload.data.persons || [];
  }

  @Mutation
  savePhotos(payload: { status: number, data: any }): void {
    if (!payload.data || !payload.data.result) {
      this.foundPhotos = [];
      this.photosTotal_ = 0;
      return;
    }

    this.foundPhotos = payload.data.result.values || [];
    this.photosTotal_ = payload.data.result.total || 0;
  }

  @Mutation
  saveAdditionalPhotos(payload: { status: number, data: any }): void {
    if (!payload.data || !payload.data.result) {
      this.additionalPhotos_ = [];
      this.additionalPhotosTotal_ = 0;
      return;
    }

    this.additionalPhotos_ = payload.data.result.values || [];
    this.additionalPhotosTotal_ = payload.data.result.total || 0;
  }

  @Mutation
  savePhotosAll(payload: { status: number, data: any }): void {
    if (!payload.data) {
      this.foundPhotosAll = [];
      return;
    }

    this.foundPhotosAll = payload.data;
  }

  @Mutation
  savePhotosPreview(payload: { status: number, data: any }): void {
    if (!payload.data || !payload.data.result) {
      this.foundPhotosPreview = [];
      return;
    }

    this.foundPhotosPreview = payload.data.result.values;
  }

  @Mutation
  saveEventImages(payload: { status: number, data: any }): void {
    if (!payload.data || !payload.data.result) {
      this.eventImages_ = [];
      return;
    }

    this.eventImages_ = payload.data.result.values;
  }
}
