
import Dinero from "dinero.js";
import CC from "currency-codes";

export default class MoneyFormat {
  
  public static format(amount: number, currencyId: string, onlyInteger: boolean = true) {
    const currency = this.getCurrency(currencyId);
    const precision = this.getPrecision(currencyId);
    
    const preferInteger = amount === parseInt(amount.toFixed());

    let result: string = Dinero({ 
      amount: parseInt((amount * Math.pow(10, precision)).toFixed()), 
      currency: currency,
      precision: precision,
    }).toFormat(onlyInteger || preferInteger ? '$0' : undefined);

    const w: any = window;
    const theme = w.MYSPORT_PHOTO_APP_SETTINGS ? w.MYSPORT_PHOTO_APP_SETTINGS.theme : null;
    const lang = w.MYSPORT_PHOTO_APP_SETTINGS ? w.MYSPORT_PHOTO_APP_SETTINGS.lang : "en";
    if (theme && lang == 'ru' && currencyId == '643') {
      result = result.slice(0, result.length-1) + "Р";
    }

    return result;
  }

  public static getCurrency(currencyId: string): any {
    const num = CC.number(currencyId);
    if (num) {
      return num.code.toString();
    } else {
      return "";
    }
  }

  private static getPrecision(currencyId: string): number {
    const num = CC.number(currencyId);
    if (num) {
      return num.digits;
    } else {
      return 2;
    }
  }

}
